body, input, button {
    font-size: 1em;
}
.main {
    padding: 20px;
}
.add-player {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
}
.add-player input {
    flex-grow: 1;
}
.add-score {
    display: flex;
    justify-content: end;
}
.add-score input {
    max-width: 25vw;
}
.player {
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding-bottom: 20px;
    gap: 20px;
}
.player-info {
    display: flex;
    align-items: center;
}
button {
    border: 1px solid gray;
    white-space: nowrap;
}